<template>
  <div>
    <rate-modifier-table
      :static-mode="staticMode"
      :model="model"
      :disabled="disabled"
      code
      portal
      @change="handleChange"
    />

    <rate-modifier-surcharge
      v-if="!staticMode || model.surcharges.length > 0"
      class="border-top"
      :static-mode="staticMode"
      :disabled="disabled"
      :model="model.surcharges"
      no-add-btn
      @change="handleSurcharges"
    />
  </div>
</template>

<script>
// Components
import RateModifierSurcharge from './RateModifierSurcharge.vue'
import RateModifierTable from './RateModifierTable.vue'

export default {
  name: 'RateModifierChargeRow',
  emits: ['change', 'delete'],
  components: {
    RateModifierTable,
    RateModifierSurcharge
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    type: {
      type: String,
      default () {
        return ''
      }
    },
    removeBtn: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: 0,
          name: '',
          comment: '',
          conditions: [],
          surcharges: [],
          rates: []
        }
      }
    }
  },
  data () {
    return {
      names: {
        'products': 'Products',
        'items': 'Items',
        'palletized': 'Pallets',
        'batch': 'Batch & EXP'
      }
    }
  },
  methods: {
    handleInsert (option, editor) {
      const data = {
        content: option.name,
        props: {
          style: 'background: orange;',
          'data-id': option.id
        }
      }

      editor.plugins.span.insert(data)
    },
    handleSurcharges (surcharges) {
      this.handleChange({ ...this.model, surcharges })
    },
    handleDelete (model) {
      this.$emit('delete', model)
    },
    handleChange (model) {
      this.$emit('change', model)
    }
  }
}
</script>
