<template>
  <div class="q-px-md q-pb-md">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :hint="$t('Add a code of an external document.')"
            :disable="disabled"
            :label="$t('Code (optional)')"
            @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>

      <div class="q-py-sm q-px-xs">
        <!--        <q-input-->
        <!--            :model-value="grace"-->
        <!--            type="number"-->
        <!--            color="white"-->
        <!--            standout="bg-teal text-white"-->
        <!--            label-color="light"-->
        <!--            :rules="[val => /^[1-9]\d*$/.test(val) || 'Please enter a positive integer.']"-->
        <!--            :disable="disabled"-->
        <!--            :label="$t('Grace Period (in days)')"-->
        <!--            @update:model-value="handleGraceInput"-->
        <!--        />-->
        <div>
          <form-builder :schema="schema" class=" q-mb-sm"/>
        </div>
      </div>
    </div>

    <h5 style="background-color: lightblue" class="text-center q-my-md">
      {{ $t('Values') }}
    </h5>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="unitSchema"/>
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span style="font-weight:bold">{{ $t('How it works:') }}</span>
        <br/>
        <span>
          {{ $t('The warehouse is charged ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.value }}
        </span>

        <span>
          {{ ' ' + $t('for each ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.unit }}
        </span>

        <span>
          {{ $t(` ${unitDimension?.unit}³`) + '. ' + $t('Volume up to ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.minUnit }}
        </span>

        <span>
          {{ $t(` ${unitDimension?.unit}³ will be charged `) }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ formulaBase }}
        </span>

        <span>
          {{ $t(', every next ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.step }}
        </span>

        <span>
          {{ $t(` ${stepUnitDimension?.unit}³ will be charged `) }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ formulaAdvance }}
        </span>

      </div>
    </div>


  </div>
</template>

<script>


export default {
  name: 'RateModifierServicesTable',
  emits: ['change', 'delete'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          period: 'month',
          periodCount: 1,
          grace: 0,
          itemStates: [],
          dimensions: {
            x: null,
            y: null,
            z: null
          },
          rates: {
            unit: 0,
            unitDimension: null,
            stepUnitDimension: null,
            value: 0,
            minUnit: 0,
            step: 0
          }
        }
      }
    }
  },
  data () {
    return {
      grace: this.model.grace,
      unitDimension: null,
      stepUnitDimension: null,
      state: this.model.itemStates || [],
      options: [
        { id: 'normal', name: 'Normal' },
        { id: 'blocked', name: 'Blocked' },
        { id: 'booked', name: 'Booked' },
        { id: 'defected', name: 'Defected' }
      ],
      dimensions: this.model.dimensions || []
    }
  },
  mounted () {
    const query = {
      per_page: 25,
    }
    this.$service.length.getAll(query)
        .then(e => {
          // this.stepUnitDimension = e[0]
          e.items.forEach(el => {
            if (this.model.rates.unitDimension && this.model.rates.unitDimension.id === el.id) {
              this.unitDimension = el
            }
            if (this.model.rates.stepUnitDimension && this.model.rates.stepUnitDimension.id === el.id) {
              this.stepUnitDimension = el
            }
          })
        })

  },
  computed: {
    formulaBase () {
      if (this.model.rates.value && this.model.rates.unit && this.model.rates.minUnit) {
        const result = (this.model.rates.value / this.model.rates.unit) * this.model.rates.minUnit
        const formattedNumber = result.toFixed(12); // "1.53000"
        const trimmedNumber = parseFloat(formattedNumber);
        return trimmedNumber
      }
      return 0
    },
    formulaAdvance () {
      if (this.model.rates.value && this.model.rates.unit && this.model.rates.step) {
        const result = (this.model.rates.value / this.model.rates.unit) * this.model.rates.step
        const formattedNumber = result.toFixed(12); // "1.53000"
        const trimmedNumber = parseFloat(formattedNumber);
        return trimmedNumber
      }
      return 0
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'grace',
                value: this.grace,
                rules: [val => /^[0-9]\d*$/.test(val) || 'Please enter a positive integer.'],
                label: this.$t('Grace Period (in days)'),
                wrapperStyleClasses: 'col-12 col-md q-pa-xs',
                onChange: (grace) => {
                  this.grace = grace
                  if (/^[0-9]\d*$/.test(grace)) {
                    if (this.model.grace !== this.grace) {
                      this.emitChange({
                        ...this.model,
                        grace
                      })
                    }
                  }
                }
              },
              {
                type: 'tag',
                label: this.$t('State'),
                value: this.state,
                field: 'itemStates',
                options: this.options,
                wrapperStyleClasses: 'col-12 col-md q-pa-xs',
                customListItem: (val) => {
                  if (typeof val === 'object') {
                    return val.name
                  }
                  return val
                },
                onRemove: (val) => {
                  this.state = this.state.filter(x => x !== val)
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.state = this.state.filter(val => {
                    if (val === value.id) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.state = [...this.state, value.id]
                  this.emitChange({
                    ...this.model,
                    itemStates: this.state
                  })
                }
              },
              {
                type: 'tag',
                label: this.$t('Include sections'),
                wrapperStyleClasses: 'col-12 q-pa-xs q-pt-md',
                value: this.model?.sections?.included || [],
                disabled: this.disabled,
                hint: 'Input (scan/write) included section barcode and press the Enter key to save it.',
                onSubmit: (value) => {
                  let label = 'included'
                  let oppositeLabel = 'excluded'

                  this.addToSection(value, label, oppositeLabel)
                },
                onRemove: (value) => {
                  let label = 'included'

                  this.removeFromSection(value, label)
                }
              },
              {
                type: 'tag',
                value: this.model?.sections?.excluded || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Excluded section'),
                hint: 'Input (scan/write) excluded section barcode and press the Enter key to save it.',
                onSubmit: (value) => {
                  let label = 'excluded'
                  let oppositeLabel = 'included'

                  this.addToSection(value, label, oppositeLabel)
                },
                onRemove: (value) => {
                  let label = 'excluded'

                  this.removeFromSection(value, label)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'X',
                field: 'x',
                hint: 'Default product dimensions',
                value: this.dimensions.x,
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: (x) => {
                  this.handleDimensionsChange({ x }, 'x')
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Y',
                field: 'y',
                value: this.dimensions.y,
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: (y) => {
                    this.handleDimensionsChange({ y }, 'y')
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Z',
                field: 'z',
                value: this.dimensions.z,
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: (z) => {
                  this.handleDimensionsChange({ z }, 'z')
                }
              }
            ]
          }
        ]
      }
    },
    unitSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'unit',
                value: this.model.rates.unit,
                wrapperStyleClasses: 'q-py-xs',
                label: this.$t('Unit'),
                onChange: (unit) => {
                  this.handleChange({ unit })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Unit Dimensions'),
                value: this.unitDimension,
                wrapperStyleClasses: ' q-py-xs',
                field: 'unitDimension',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.title
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.length.getAll(query)
                },
                onChange: (unitDimension) => {

                  this.unitDimension = unitDimension

                  const result = { id: this.unitDimension.id, unit: this.unitDimension.unit }
                  this.handleChange({ unitDimension: result })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'value',
                value: this.model.rates.value,
                label: this.$t('Value'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (value) => {
                  this.handleChange({ value })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'minUnit',
                value: this.model.rates.minUnit,
                label: this.$t('Min Unit'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (minUnit) => {
                  this.handleChange({ minUnit })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'step',
                disabled: this.staticMode || this.disabled,
                value: this.model.rates.step,
                label: this.$t('Step'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (step) => {
                  this.handleChange({ step })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Step Unit Dimensions'),
                value: this.stepUnitDimension,
                wrapperStyleClasses: ' q-py-xs',
                field: 'stepUnitDimension',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.title
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.length.getAll(query)
                },
                onChange: (stepUnitDimension) => {

                  this.stepUnitDimension = stepUnitDimension

                  const result = { id: this.stepUnitDimension.id, unit: this.stepUnitDimension.unit }
                  this.handleChange({ stepUnitDimension: result })
                }
              }
            ]
          }
        ]
      }
    },
  },
  methods: {
    handleGraceInput (grace) {
      this.grace = grace
      if (/^[1-9]\d*$/.test(grace)) {

        if (this.model.grace !== this.grace) {
          this.emitChange({
            ...this.model,
            grace
          })
        }
      }

    },
    handleRemove () {
      this.$emit('delete', this.model)
    },
    emitChange (model) {
      this.$emit('change', model)
    },
    handleInput (code) {
      this.emitChange({
        ...this.model,
        code
      })
    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleChange (update) {
      return this.$emit('change', { ...this.model, rates: { ...this.model.rates, ...update } })
    },
    handleDimensionsChange (update) {
      const [key, value] = Object.entries(update)[0];
      this.dimensions[key] = value
      if (/^[0-9]\d*$/.test(value)) {
          if (this.model.dimensions?.[key] !== this.dimensions[key]) {
              return this.emitChange({
                  ...this.model, dimensions: {
                      ...this.model.dimensions,
                      update
                  }
              })
          }
      }
    },
    addToSection (value, label, oppositeLabel) {
      if (!value) {
        return
      }

      if (!this.model.sections || this.model.sections.length === 0) {
        this.model.sections = {
          [label]: [],
          [oppositeLabel]: []
        }
      }

      let isExist = false
      if (this.model.sections[label]) {
        this.model.sections[label].filter(val => {
          if (val === value) {
            isExist = true
          }
        })
      }

      if (!isExist) {
        this.model.sections[label].push(value)
        this.model.sections[oppositeLabel] = []

        this.$emit('change', this.model)
      }
    },
    removeFromSection (value, label) {
      if (!value) {
        return
      }

      let isExist = false
      let key = null

      this.model.sections[label].forEach((val, index) => {
        if (val === value) {
          isExist = true
          key = index
        }
      })

      if (isExist) {
        this.model.sections[label].splice(key, 1)

        this.$emit('change', this.model)
      }
    }
  }
}
</script>
